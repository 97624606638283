// WAREHOUSE
export const WAREHOUSE_TRANSFER_KEY = 'WAREHOUSE_KEY'
export const WAREHOUSE_KEY = 'WAREHOUSE_KEY'

// STAFF
export const STAFF_KEY = 'STAFF_KEY'

// STATISTICAL
export const DISCOUNT_REPORT_KEY = 'DISCOUNT_REPORT_KEY'

export const REAL_SALES_KEY = 'REAL_SALES_KEY'

export const IMPORT_SALES_KEY = 'IMPORT_SALES_KEY'

export const GUARANTEE_PRODUCT_KEY = 'GUARANTEE_PRODUCT_KEY'

export const STAFF_SALARY_KEY = 'STAFF_SALARY_KEY'

// CONFIG
export const IMPORT_TARGET_KEY = 'IMPORT_TARGET_KEY'

export const TARGET_KEY = 'TARGET_KEY'
