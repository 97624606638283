export const LOGIN = '/login'
export const LOGOUT = '/logout'
// home
export const LIST_HOME = '/home/list'
// productgroup
export const LIST_PRODUCT_GROUP = '/productgroup/list'
export const CREATE_PRODUCT_GROUP = '/productgroup/create'
export const EDIT_PRODUCT_GROUP = '/productgroup/edit/'
export const UPDATE_PRODUCT_GROUP = '/productgroup/update/'
export const DELETE_PRODUCT_GROUP = '/productgroup/delete/'
// products
export const LIST_PRODUCTS = '/products/list'
export const CREATE_PRODUCTS = '/products/create'
export const UPDATE_PRODUCTS = '/products/update/'
export const FILTER_PRODUCTS = '/products/filter'
export const DELETE_PRODUCTS = '/products/delete/'
export const EDIT_PRODUCTS = '/products/edit/'
export const UPDATE_STATUS_PRODUCTS = '/products/status/'
// warehouse
export const CREATE_WAREHOUSE = '/warehouse/create'
export const LIST_WAREHOUSE = '/warehouse/list'
export const EDIT_WAREHOUSE = '/warehouse/edit/'
export const UPDATE_WAREHOUSE = '/warehouse/update/'
export const DELETE_WAREHOUSE = '/warehouse/delete/'
// discount
export const LIST_DISCOUNT = '/discount/list'
export const CREATE_DISCOUNT = '/discount/create'
export const DELETE_DISCOUNT = '/discount/delete/'
export const FILTER_DISCOUNT = '/discount/filter'
export const GET_ITEM_DISCOUNT = '/discount/get/item/'
export const UPDATE_ITEM_DISCOUNT = '/discount/update/item/'
// purchases
export const GET_PURCHASES = '/purchases/get'
export const CREATE_ITEM_PURCHASES = '/purchases/create'
export const LIST_PURCHASES = '/purchases/list'
export const GET_PURCHASES_DETAIL = '/purchases/detail/'
export const DELETE_PURCHASES = '/purchases/delete/'
export const EDIT_ITEM_PURCHASES = '/purchases/edit/'
export const UPDATE_ITEM_PURCHASES = '/purchases/update/'
export const FILTER_PURCHASES = '/purchases/filter'
export const UPLOAD_FILE_PURCHASES = '/purchases/upload/file'
// staff
export const LIST_STAFF = '/staff/list'
export const CREATE_STAFF = '/staff/create'
export const EDIT_STAFF = '/staff/edit/'
export const UPDATE_STAFF = '/staff/update/'
export const DELETE_ITEM_STAFF = '/staff/delete/'
export const GET_ITEM_STAFF = '/staff/get/debt/'
export const UPDATE_ITEM_STAFF = '/staff/update/debt/'
export const UPDATE_STAFF_STATUS = '/staff/update/status/'
// location
export const LIST_LOCATION = '/location/list'
export const CREATE_LOCATION = '/location/create'
export const EDIT_LOCATION = '/location/edit/'
export const UPDATE_LOCATION = '/location/update/'
// customer
export const LIST_CUSTOMER = '/customers/list'
export const CREATE_CUSTOMER = '/customers/create'
export const GET_DEBT_CUSTOMER = '/customers/get/debt/'
export const UPDATE_DEBT_CUSTOMER = '/customers/update/debt/'
export const EDIT_CUSTOMER = '/customers/edit/'
export const UPDATE_CUSTOMER = '/customers/update/'
export const ORDER_CUSTOMER = '/customers/order/'
export const LIST_PRODUCTS_ORDER = '/customers/products/order/'

// sales
export const GET_CREATE_SALES = '/sales/get/create'
export const CREATE_SALES = '/sales/create'
export const List_SALES = '/sales/list'
export const EDIT_ITEM_SALES = '/sales/edit/'
export const UPDATE_SALES = '/sales/update/'
export const CREATE_PAID = '/sales/create/paid'
export const DELETE_SALES = '/sales/delete/'
export const UPDATE_SALES_STATUS = '/sales/update/status/'
export const GET_SALES_BILL = '/sales/get/bill/'
export const FILTER_PRODUCTS_SALES = '/sales/filter/products/'
export const FILTER_SALES = '/sales/filter'

// statistic
export const DISCOUNT_REPORT = '/statistical/discount-report'
export const REAL_SALES = '/statistical/real-sales'
export const IMPORT_SALES = '/statistical/import-sales'
export const GUARANTEE_PRODUCT = '/statistical/guarantee-product'
export const STAFF_SALARY = '/statistical/staff-salary'

// config
export const IMPORT_TARGET = '/target/target-purchase'
export const IMPORT_TARGET_CREATE = '/target/target-purchase-create'
export const TARGET = '/target/target'
export const TARGET_CREATE = '/target/target-create'
